import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'

export default function Template({ data }) {
	const { markdownRemark } = data
	const { frontmatter, html, excerpt } = markdownRemark
	return (
		<Layout title={frontmatter.title}>
			<div className="article">
				<Helmet>
					{frontmatter.favicon && <link rel="icon" href="./favicon.ico" />}
					<meta name="description" content={excerpt} />
				</Helmet>
				<h1>{frontmatter.title}</h1>
				{frontmatter.tagline && <h2 className="page-tagline">{frontmatter.tagline}</h2>}
				<div className="post-content" dangerouslySetInnerHTML={{ __html: html }} />
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			excerpt(pruneLength: 280)
			frontmatter {
				title
				tagline
				favicon
			}
			fields {
				slug
			}
		}
	}
`
